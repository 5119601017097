@import "https://fonts.googleapis.com/css2?family=Fredoka:wght@400;700&family=Jura:wght@300;400;500;600;700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --color-gradient: linear-gradient(75deg, #03ceff, #c91fff, #03ceff, #c91fff);
  --color-gradient-2: linear-gradient(90deg, #c7b303, #040404, #c7b303);
}

@font-face {
  font-family: Promo;
  font-weight: 400;
  src: url("promo.ccdb03d2.woff2") format("woff");
}

html {
  -webkit-tap-highlight-color: transparent;
  background-color: #000;
  font-family: Orbitron, sans-serif;
}

body {
  color: #fff;
  background: #000;
  width: 100%;
  height: 100%;
}

#app-root {
  height: 100%;
  overflow: hidden auto;
}

body:has(dialog:open) {
  overflow: hidden;
}

.page {
  max-width: 1920px;
  margin: 0 auto;
}

#landing h2 {
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}

@media (width >= 1024px) {
  #landing h2 {
    font-size: 3rem;
    line-height: 1;
  }
}

#landing h3 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

@media (width >= 1024px) {
  #landing h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.aspect-h-9 {
  --tw-aspect-h: 9;
}

.aspect-w-16 {
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 16;
  position: relative;
}

.aspect-w-16 > * {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.toggle-checkbox {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  column-gap: .5rem;
  display: flex;
}

.toggle-checkbox > input {
  display: none;
}

.toggle-checkbox > input:checked + span {
  background-color: #42d77d;
}

.toggle-checkbox > input:checked + span:after {
  left: 1em;
}

.toggle-checkbox > span {
  cursor: pointer;
  background-color: #d7d8da;
  border-radius: 15px;
  width: 2em;
  height: 1em;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
  position: relative;
}

.toggle-checkbox > span:after {
  content: "";
  background-color: #fff;
  border-radius: 15px;
  width: .93em;
  height: .93em;
  transition: left .3s ease-out;
  position: absolute;
  top: .03em;
  left: .03em;
}

.button-gradient {
  background-image: var(--color-gradient);
  z-index: 1;
  background-size: 300%;
  padding: .6em 3em;
  font-weight: bold;
  position: relative;
}

.button-gradient:after {
  content: "";
  background-image: var(--color-gradient);
  filter: blur(7px);
  z-index: -1;
  background-size: 300%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.button-gradient-animate, .button-gradient-animate:after {
  animation: 8s infinite backgroud-gradient-animation;
}

@keyframes backgroud-gradient-animation {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.button-default {
  background-image: linear-gradient(100deg, #c91fff -15%, #03ceff 170%);
  border-radius: .5rem;
  padding: .5rem 2rem;
  font-weight: 600;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button-default:hover {
  --tw-scale-x: 105%;
  --tw-scale-y: 105%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  outline-style: solid;
}

.input-team-tools {
  border-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  background-color: inherit;
  color: inherit;
  outline-offset: 2px;
  border-radius: .25rem;
  outline: 2px solid #0000;
  padding: .5rem;
}

.input-team-tools::placeholder {
  opacity: .5;
}

.input-team-tools::-webkit-calendar-picker-indicator {
  filter: invert();
  cursor: pointer;
}

input.login-form {
  text-align: center;
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-color: #00000080;
  border-width: 1px;
  width: 100%;
  padding: 1rem;
}

.login-form.error {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-color: #00000080;
  padding: .25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.login-form.success {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-color: #00000080;
  padding: .25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.login-form.error:empty {
  display: none;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.-bottom-\[1px\] {
  bottom: -1px;
}

.-left-\[5\%\] {
  left: -5%;
}

.-left-full {
  left: -100%;
}

.-right-2 {
  right: -.5rem;
}

.-right-8 {
  right: -2rem;
}

.-top-full {
  top: -100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-8 {
  bottom: 2rem;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.left-1\/2 {
  left: 50%;
}

.left-2 {
  left: .5rem;
}

.left-full {
  left: 100%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-10 {
  right: 2.5rem;
}

.right-2 {
  right: .5rem;
}

.right-3 {
  right: .75rem;
}

.right-8 {
  right: 2rem;
}

.right-\[0\.5em\] {
  right: .5em;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-10 {
  top: 2.5rem;
}

.top-16 {
  top: 4rem;
}

.top-2 {
  top: .5rem;
}

.top-3 {
  top: .75rem;
}

.top-\[5\%\] {
  top: 5%;
}

.top-\[50\%\] {
  top: 50%;
}

.top-full {
  top: 100%;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.z-\[9999\] {
  z-index: 9999;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-\[-2\] {
  grid-column-start: -2;
}

.col-end-\[-1\] {
  grid-column-end: -1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.-m-4 {
  margin: -1rem;
}

.m-1 {
  margin: .25rem;
}

.m-16 {
  margin: 4rem;
}

.m-4 {
  margin: 1rem;
}

.m-\[1px\] {
  margin: 1px;
}

.m-auto {
  margin: auto;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-mb-\[1px\] {
  margin-bottom: -1px;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-\[0\.5em\] {
  margin-top: -.5em;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-52 {
  margin-top: 13rem;
}

.mt-8 {
  margin-top: 2rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.\!inline {
  display: inline !important;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\/2 {
  height: 50%;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-20 {
  height: 5rem;
}

.h-3 {
  height: .75rem;
}

.h-3\/4 {
  height: 75%;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-\[10\%\] {
  height: 10%;
}

.h-\[15vw\] {
  height: 15vw;
}

.h-\[35\%\] {
  height: 35%;
}

.h-\[37\.5vw\] {
  height: 37.5vw;
}

.h-\[40\%\] {
  height: 40%;
}

.h-\[55\%\] {
  height: 55%;
}

.h-\[90\%\] {
  height: 90%;
}

.h-\[min\(1080px\,100vw\/16\*9\)\] {
  height: min(1080px, 56.25vw);
}

.h-\[min\(calc\(9\/16\*100vw\)\,1080px\)\] {
  height: min(56.25vw, 1080px);
}

.h-full {
  height: 100%;
}

.h-max {
  height: max-content;
}

.h-screen {
  height: 100vh;
}

.max-h-0 {
  max-height: 0;
}

.max-h-4 {
  max-height: 1rem;
}

.max-h-40 {
  max-height: 10rem;
}

.max-h-5 {
  max-height: 1.25rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-\[1080px\] {
  max-height: 1080px;
}

.max-h-\[142px\] {
  max-height: 142px;
}

.max-h-\[1em\] {
  max-height: 1em;
}

.max-h-\[1rem\] {
  max-height: 1rem;
}

.max-h-\[37\.5vw\] {
  max-height: 37.5vw;
}

.max-h-full {
  max-height: 100%;
}

.max-h-max {
  max-height: max-content;
}

.min-h-\[12rem\] {
  min-height: 12rem;
}

.min-h-\[2rem\] {
  min-height: 2rem;
}

.min-h-\[3em\] {
  min-height: 3em;
}

.min-h-\[75vh\] {
  min-height: 75vh;
}

.min-h-\[calc\(100\%-11rem\)\] {
  min-height: calc(100% - 11rem);
}

.min-h-screen {
  min-height: 100vh;
}

.w-1 {
  width: .25rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-2\/5 {
  width: 40%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-20 {
  width: 5rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/4 {
  width: 75%;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-40 {
  width: 10rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-52 {
  width: 13rem;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[110\%\] {
  width: 110%;
}

.w-\[15ch\] {
  width: 15ch;
}

.w-\[15vw\] {
  width: 15vw;
}

.w-\[4ch\] {
  width: 4ch;
}

.w-\[5ch\] {
  width: 5ch;
}

.w-\[6ch\] {
  width: 6ch;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-min {
  width: min-content;
}

.w-screen {
  width: 100vw;
}

.min-w-\[4ch\] {
  min-width: 4ch;
}

.min-w-\[75\%\] {
  min-width: 75%;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.min-w-min {
  min-width: min-content;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[100vw\] {
  max-width: 100vw;
}

.max-w-\[1313px\] {
  max-width: 1313px;
}

.max-w-\[15\%\] {
  max-width: 15%;
}

.max-w-\[1920px\] {
  max-width: 1920px;
}

.max-w-\[1rem\] {
  max-width: 1rem;
}

.max-w-\[412px\] {
  max-width: 412px;
}

.max-w-\[508px\] {
  max-width: 508px;
}

.max-w-\[704px\] {
  max-width: 704px;
}

.max-w-\[867px\] {
  max-width: 867px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-max {
  max-width: max-content;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.basis-full {
  flex-basis: 100%;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-2 {
  --tw-border-spacing-x: .5rem;
  --tw-border-spacing-y: .5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-4 {
  --tw-border-spacing-x: 1rem;
  --tw-border-spacing-y: 1rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-2 {
  --tw-border-spacing-y: .5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.origin-top-left {
  transform-origin: 0 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-2 {
  --tw-rotate: -2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-2 {
  --tw-rotate: 2deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[135deg\] {
  --tw-rotate: 135deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.1\] {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: .5s ease-in-out fadeIn;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  animation: 60s linear infinite marquee;
}

@keyframes marquee2 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.animate-marquee2 {
  animation: 60s linear infinite marquee2;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.select-text {
  -webkit-user-select: text;
  user-select: text;
}

.resize {
  resize: both;
}

.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.snap-start {
  scroll-snap-align: start;
}

.snap-center {
  scroll-snap-align: center;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  appearance: none;
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-\[1fr\,4fr\] {
  grid-template-columns: 1fr 4fr;
}

.grid-cols-\[1fr_1fr_0\.5fr_0\.5fr_0\.5fr_max-content\] {
  grid-template-columns: 1fr 1fr .5fr .5fr .5fr max-content;
}

.grid-cols-\[2\.5rem\,1fr\] {
  grid-template-columns: 2.5rem 1fr;
}

.grid-cols-\[8rem\,8rem\] {
  grid-template-columns: 8rem 8rem;
}

.grid-cols-\[max-content\,1fr\,max-content\] {
  grid-template-columns: max-content 1fr max-content;
}

.grid-cols-\[max-content\,1fr\] {
  grid-template-columns: max-content 1fr;
}

.grid-cols-\[max-content\,3fr\,1fr\] {
  grid-template-columns: max-content 3fr 1fr;
}

.grid-cols-\[max-content\,auto\] {
  grid-template-columns: max-content auto;
}

.grid-cols-\[max-content\,max-content\,0\] {
  grid-template-columns: max-content max-content 0;
}

.grid-cols-\[repeat\(2\,max-content\)\] {
  grid-template-columns: repeat(2, max-content);
}

.grid-cols-\[repeat\(3\,max-content\)\] {
  grid-template-columns: repeat(3, max-content);
}

.grid-cols-\[repeat\(4\,max-content\)\] {
  grid-template-columns: repeat(4, max-content);
}

.grid-cols-\[repeat\(5\,max-content\)\] {
  grid-template-columns: repeat(5, max-content);
}

.grid-cols-\[repeat\(6\,max-content\)\] {
  grid-template-columns: repeat(6, max-content);
}

.grid-cols-\[repeat\(6\,minmax\(max-content\,1fr\)\)\] {
  grid-template-columns: repeat(6, minmax(max-content, 1fr));
}

.grid-cols-\[repeat\(7\,max-content\)\,1fr\] {
  grid-template-columns: repeat(7, max-content) 1fr;
}

.grid-cols-\[repeat\(8\,max-content\)\] {
  grid-template-columns: repeat(8, max-content);
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.place-content-start {
  place-content: start;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.place-self-end {
  place-self: end;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-self-end {
  justify-self: end;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-clip {
  overflow-x: clip;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.rounded-bl {
  border-bottom-left-radius: .25rem;
}

.rounded-br {
  border-bottom-right-radius: .25rem;
}

.rounded-tl-xl {
  border-top-left-radius: .75rem;
}

.rounded-tr-xl {
  border-top-right-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-4 {
  border-width: 4px;
}

.border-\[0\.5rem\] {
  border-width: .5rem;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-r-\[12px\] {
  border-right-width: 12px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-\[\#00FF90\] {
  --tw-border-opacity: 1;
  border-color: rgb(0 255 144 / var(--tw-border-opacity));
}

.border-\[\#D9D9D9\] {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
}

.border-\[\#FFE600\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 230 0 / var(--tw-border-opacity));
}

.border-\[rgba\(255\,255\,255\,0\.25\)\] {
  border-color: #ffffff40;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-black\/50 {
  border-color: #00000080;
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-white\/10 {
  border-color: #ffffff1a;
}

.border-white\/20 {
  border-color: #fff3;
}

.border-white\/30 {
  border-color: #ffffff4d;
}

.border-white\/50 {
  border-color: #ffffff80;
}

.border-white\/70 {
  border-color: #ffffffb3;
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity));
}

.border-opacity-20 {
  --tw-border-opacity: .2;
}

.border-opacity-50 {
  --tw-border-opacity: .5;
}

.border-opacity-60 {
  --tw-border-opacity: .6;
}

.border-opacity-75 {
  --tw-border-opacity: .75;
}

.bg-\[\#0088D5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 136 213 / var(--tw-bg-opacity));
}

.bg-\[\#131313\] {
  --tw-bg-opacity: 1;
  background-color: rgb(19 19 19 / var(--tw-bg-opacity));
}

.bg-\[\#141414\] {
  --tw-bg-opacity: 1;
  background-color: rgb(20 20 20 / var(--tw-bg-opacity));
}

.bg-\[\#151218\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 18 24 / var(--tw-bg-opacity));
}

.bg-\[\#252525\] {
  --tw-bg-opacity: 1;
  background-color: rgb(37 37 37 / var(--tw-bg-opacity));
}

.bg-\[\#2B1700\] {
  --tw-bg-opacity: 1;
  background-color: rgb(43 23 0 / var(--tw-bg-opacity));
}

.bg-\[\#484100\] {
  --tw-bg-opacity: 1;
  background-color: rgb(72 65 0 / var(--tw-bg-opacity));
}

.bg-\[\#9251FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(146 81 255 / var(--tw-bg-opacity));
}

.bg-\[\#FF1FC0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 31 192 / var(--tw-bg-opacity));
}

.bg-\[\#d9d9d9\]\/10 {
  background-color: #d9d9d91a;
}

.bg-\[\#e47ba6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(228 123 166 / var(--tw-bg-opacity));
}

.bg-\[rgba\(0\,0\,0\,0\.1\)\] {
  background-color: #0000001a;
}

.bg-\[rgba\(20\,18\,24\,0\.85\)\] {
  background-color: #141218d9;
}

.bg-\[rgba\(200\,200\,200\,0\.1\)\] {
  background-color: #c8c8c81a;
}

.bg-\[rgba\(201\,31\,255\,0\.75\)\] {
  background-color: #c91fffbf;
}

.bg-\[rgba\(21\,18\,24\,0\.52\)\] {
  background-color: #15121885;
}

.bg-\[rgba\(21\,18\,25\,0\.52\)\] {
  background-color: #15121985;
}

.bg-\[rgba\(255\,255\,255\,0\.05\)\] {
  background-color: #ffffff0d;
}

.bg-\[rgba\(255\,255\,255\,0\.2\)\] {
  background-color: #fff3;
}

.bg-\[rgba\(255\,255\,255\,0\.25\)\] {
  background-color: #ffffff40;
}

.bg-\[rgba\(61\,61\,61\,0\.25\)\] {
  background-color: #3d3d3d40;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/25 {
  background-color: #00000040;
}

.bg-black\/30 {
  background-color: #0000004d;
}

.bg-black\/50 {
  background-color: #00000080;
}

.bg-black\/60 {
  background-color: #0009;
}

.bg-black\/70 {
  background-color: #000000b3;
}

.bg-black\/75 {
  background-color: #000000bf;
}

.bg-black\/80 {
  background-color: #000c;
}

.bg-black\/90 {
  background-color: #000000e6;
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-200\/5 {
  background-color: #e5e7eb0d;
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-500\/10 {
  background-color: #6b72801a;
}

.bg-gray-500\/20 {
  background-color: #6b728033;
}

.bg-gray-600\/20 {
  background-color: #4b556333;
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-500\/80 {
  background-color: #22c55ecc;
}

.bg-inherit {
  background-color: inherit;
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

.bg-neutral-900\/50 {
  background-color: #17171780;
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-red-500\/80 {
  background-color: #ef4444cc;
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-slate-300\/10 {
  background-color: #cbd5e11a;
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-violet-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/10 {
  background-color: #ffffff1a;
}

.bg-white\/20 {
  background-color: #fff3;
}

.bg-white\/5 {
  background-color: #ffffff0d;
}

.bg-white\/50 {
  background-color: #ffffff80;
}

.bg-yellow-500\/50 {
  background-color: #eab30880;
}

.bg-yellow-500\/80 {
  background-color: #eab308cc;
}

.bg-zinc-800\/50 {
  background-color: #27272a80;
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-\[image\:radial-gradient\(circle\,black_0\%\,black_30\%\,transparent_30\%\)\] {
  background-image: radial-gradient(circle, #000 0% 30%, #0000 30%);
}

.bg-\[linear-gradient\(120deg\,rgba\(3\,206\,255\,0\.25\)_0\%\,rgba\(201\,31\,255\,0\.25\)_100\%\)\] {
  background-image: linear-gradient(120deg, #03ceff40 0%, #c91fff40 100%);
}

.bg-\[linear-gradient\(142deg\,\#03CEFF_0\%\,\#C91FFF_100\%\)\] {
  background-image: linear-gradient(142deg, #03ceff 0%, #c91fff 100%);
}

.bg-\[linear-gradient\(149\.79deg\,\#C91FFF_13\.94\%\,\#03CEFF_91\.37\%\)\] {
  background-image: linear-gradient(149.79deg, #c91fff 13.94%, #03ceff 91.37%);
}

.bg-\[linear-gradient\(160deg\,\#03CEFF_0\%\,\#C91FFF_100\%\)\] {
  background-image: linear-gradient(160deg, #03ceff 0%, #c91fff 100%);
}

.bg-\[linear-gradient\(162deg\,\#03CEFF_0\%\,\#C91FFF_100\%\)\] {
  background-image: linear-gradient(162deg, #03ceff 0%, #c91fff 100%);
}

.bg-\[linear-gradient\(269\.59deg\,\#03ceff_-11\.05\%\,\#c91fff_52\.25\%\,\#00ff90_112\.98\%\)\] {
  background-image: linear-gradient(269.59deg, #03ceff -11.05%, #c91fff 52.25%, #00ff90 112.98%);
}

.bg-\[linear-gradient\(269deg\,\#004F2D_-10\.99\%\,\#00FF90_130\.96\%\)\] {
  background-image: linear-gradient(269deg, #004f2d -10.99%, #00ff90 130.96%);
}

.bg-\[linear-gradient\(269deg\,\#C91FFF_-10\.99\%\,\#03CEFF_130\.96\%\)\] {
  background-image: linear-gradient(269deg, #c91fff -10.99%, #03ceff 130.96%);
}

.bg-\[linear-gradient\(270deg\,\#C91FFF_-15\%\,\#03CEFF_170\%\)\] {
  background-image: linear-gradient(270deg, #c91fff -15%, #03ceff 170%);
}

.bg-\[linear-gradient\(90\.01deg\,\#C91FFF_0\%\,\#03CEFF_100\%\)\] {
  background-image: linear-gradient(90.01deg, #c91fff 0%, #03ceff 100%);
}

.bg-\[linear-gradient\(91\.26deg\,\#C91FFF_22\.57\%\,\#03CEFF_99\.93\%\)\] {
  background-image: linear-gradient(91.26deg, #c91fff 22.57%, #03ceff 99.93%);
}

.bg-\[url\(\/src\/js\/shared\/assets\/img\/bg\.auth\.png\?as\=webp\)\] {
  background-image: url("bg.auth.eb3c2df9.webp");
}

.bg-\[url\(\/src\/js\/shared\/assets\/img\/bg\.signup_success\.png\?as\=webp\)\] {
  background-image: url("bg.signup_success.71241177.webp");
}

.bg-black-gradient {
  background-image: linear-gradient(94.27deg, #414141 -17.36%, #000 139.18%);
}

.bg-br1-gradient {
  background-image: linear-gradient(94.38deg, #03ceff -17.38%, #c91fff 108.85%);
}

.bg-custom-gradient-1 {
  background-image: linear-gradient(271.47deg, #c91fff -9.07%, #03ceff 110.62%);
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-\[\#03CEFF\] {
  --tw-gradient-from: #03ceff var(--tw-gradient-from-position);
  --tw-gradient-to: #03ceff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#661181\] {
  --tw-gradient-from: #661181 var(--tw-gradient-from-position);
  --tw-gradient-to: #66118100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#C7B303\] {
  --tw-gradient-from: #c7b303 var(--tw-gradient-from-position);
  --tw-gradient-to: #c7b30300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-black {
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-emerald-600 {
  --tw-gradient-from: #059669 var(--tw-gradient-from-position);
  --tw-gradient-to: #05966900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-neutral-800 {
  --tw-gradient-from: #262626 var(--tw-gradient-from-position);
  --tw-gradient-to: #26262600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-800 {
  --tw-gradient-from: #27272a var(--tw-gradient-from-position);
  --tw-gradient-to: #27272a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#006882\] {
  --tw-gradient-to: #006882 var(--tw-gradient-to-position);
}

.to-\[\#040404\] {
  --tw-gradient-to: #040404 var(--tw-gradient-to-position);
}

.to-\[\#C91FFF\] {
  --tw-gradient-to: #c91fff var(--tw-gradient-to-position);
}

.to-black {
  --tw-gradient-to: #000 var(--tw-gradient-to-position);
}

.to-black\/0 {
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
}

.to-emerald-950 {
  --tw-gradient-to: #022c22 var(--tw-gradient-to-position);
}

.to-neutral-900 {
  --tw-gradient-to: #171717 var(--tw-gradient-to-position);
}

.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.bg-\[size\:2px_2px\] {
  background-size: 2px 2px;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.bg-\[center_right\] {
  background-position: 100%;
}

.bg-center {
  background-position: center;
}

.bg-right-top {
  background-position: 100% 0;
}

.bg-top {
  background-position: top;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-\[\#00FF90\] {
  fill: #00ff90;
}

.fill-violet-600 {
  fill: #7c3aed;
}

.fill-white {
  fill: #fff;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-bottom {
  object-position: bottom;
}

.object-left {
  object-position: left;
}

.object-right-top {
  object-position: right top;
}

.object-top {
  object-position: top;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[1px\] {
  padding: 1px;
}

.p-\[3\.5\%\] {
  padding: 3.5%;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.font-jura {
  font-family: Jura, sans-serif;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-roboto {
  font-family: Roboto, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-\[0\.5rem\] {
  font-size: .5rem;
}

.text-\[0\.65em\] {
  font-size: .65em;
}

.text-\[0\.65rem\] {
  font-size: .65rem;
}

.text-\[0\.6em\] {
  font-size: .6em;
}

.text-\[0\.6rem\] {
  font-size: .6rem;
}

.text-\[0\.7em\] {
  font-size: .7em;
}

.text-\[0\.7rem\] {
  font-size: .7rem;
}

.text-\[0\.9em\] {
  font-size: .9em;
}

.text-\[50\%\] {
  font-size: 50%;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-relaxed {
  line-height: 1.625;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-\[\#00FF90\] {
  --tw-text-opacity: 1;
  color: rgb(0 255 144 / var(--tw-text-opacity));
}

.text-\[\#03CEFF\] {
  --tw-text-opacity: 1;
  color: rgb(3 206 255 / var(--tw-text-opacity));
}

.text-\[\#C91FFF\] {
  --tw-text-opacity: 1;
  color: rgb(201 31 255 / var(--tw-text-opacity));
}

.text-\[\#D40000\] {
  --tw-text-opacity: 1;
  color: rgb(212 0 0 / var(--tw-text-opacity));
}

.text-\[\#E38CFF\] {
  --tw-text-opacity: 1;
  color: rgb(227 140 255 / var(--tw-text-opacity));
}

.text-\[\#FF1FC0\] {
  --tw-text-opacity: 1;
  color: rgb(255 31 192 / var(--tw-text-opacity));
}

.text-\[rgba\(255\,255\,255\,0\.65\)\] {
  color: #ffffffa6;
}

.text-\[rgba\(255\,255\,255\,0\.75\)\] {
  color: #ffffffbf;
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-black\/50 {
  color: #00000080;
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-500\/50 {
  color: #6b728080;
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-500\/80 {
  color: #22c55ecc;
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-violet-500 {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

.text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/10 {
  color: #ffffff1a;
}

.text-white\/20 {
  color: #fff3;
}

.text-white\/25 {
  color: #ffffff40;
}

.text-white\/30 {
  color: #ffffff4d;
}

.text-white\/40 {
  color: #fff6;
}

.text-white\/5 {
  color: #ffffff0d;
}

.text-white\/50 {
  color: #ffffff80;
}

.text-white\/60 {
  color: #fff9;
}

.text-white\/70 {
  color: #ffffffb3;
}

.text-white\/75 {
  color: #ffffffbf;
}

.text-white\/80 {
  color: #fffc;
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.decoration-2 {
  text-decoration-thickness: 2px;
}

.underline-offset-2 {
  text-underline-offset: 2px;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.accent-blue-400 {
  accent-color: #60a5fa;
}

.accent-blue-500 {
  accent-color: #3b82f6;
}

.accent-green-500 {
  accent-color: #22c55e;
}

.accent-orange-400 {
  accent-color: #fb923c;
}

.accent-red-500 {
  accent-color: #ef4444;
}

.accent-violet-500 {
  accent-color: #8b5cf6;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.opacity-80 {
  opacity: .8;
}

.opacity-\[0\.2\] {
  opacity: .2;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-1 {
  outline-width: 1px;
}

.outline-\[rgba\(255\,255\,255\,0\.2\)\] {
  outline-color: #fff3;
}

.outline-\[rgba\(255\,255\,255\,0\.65\)\] {
  outline-color: #ffffffa6;
}

.outline-blue-500 {
  outline-color: #3b82f6;
}

.outline-white {
  outline-color: #fff;
}

.outline-white\/30 {
  outline-color: #ffffff4d;
}

.outline-white\/50 {
  outline-color: #ffffff80;
}

.outline-white\/80 {
  outline-color: #fffc;
}

.outline-zinc-700 {
  outline-color: #3f3f46;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-\[32px\] {
  --tw-blur: blur(32px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-lg {
  --tw-blur: blur(16px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-none {
  --tw-blur: blur(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-50 {
  --tw-brightness: brightness(.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-75 {
  --tw-brightness: brightness(.75);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-2xl {
  --tw-backdrop-blur: blur(40px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-\[2px\] {
  --tw-backdrop-blur: blur(2px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-none {
  transition-property: none;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-1000 {
  transition-delay: 1s;
}

.delay-200 {
  transition-delay: .2s;
}

.delay-\[1500ms\] {
  transition-delay: 1.5s;
}

.duration-1000 {
  transition-duration: 1s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-700 {
  transition-duration: .7s;
}

.duration-75 {
  transition-duration: 75ms;
}

.duration-\[1s\] {
  transition-duration: 1s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.flat-scrollbar::-webkit-scrollbar {
  background-color: #ffffff26;
  width: .75em;
}

.flat-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ffffff96;
}

.bg-silver {
  background: linear-gradient(#fff 23.37%, #6b6b6bb8 175%);
}

.clip-text {
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.gradient-vertical-opacity {
  background: linear-gradient(#15121800 0%, #000 100%);
}

.scrollbar-tiny::-webkit-scrollbar {
  background: none;
  width: 12px;
}

.scrollbar-tiny::-webkit-scrollbar-thumb {
  border: 4px solid #0000;
  border-radius: 14px;
  box-shadow: inset 0 0 14px 14px #bbbbbe;
}

.scrollbar-tiny::-webkit-scrollbar-track {
  border: 4px solid #0000;
  box-shadow: inset 0 0 14px 14px #0000;
}

.no-flip {
  z-index: 0;
  transform: rotateY(0);
}

.-rotate-flip {
  backface-visibility: hidden;
  animation: .8s z-layer;
  transform: rotateY(-180deg)translateX(1px);
}

.rotate-flip {
  backface-visibility: hidden;
  animation: .8s z-layer;
  transform: rotateY(180deg)translateX(-1px);
}

.perspective {
  perspective: 900px;
}

@keyframes z-layer {
  0% {
    z-index: 1;
  }

  100% {
    z-index: 0;
  }
}

.animate-fade-in-out {
  animation: 1s linear forwards fadeInOut;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translate(-50%);
  }

  50% {
    opacity: .8;
    transform: translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
}

@keyframes textUnknown {
  0% {
    content: "@#$%";
  }

  5% {
    content: "#$%@";
  }

  10% {
    content: "$%@#";
  }

  15% {
    content: "%@#$";
  }

  20% {
    content: "@#$%";
  }
}

.\[animation-fill-mode\:forwards\] {
  animation-fill-mode: forwards;
}

.\[backface-visibility\:hidden\] {
  backface-visibility: hidden;
}

.\[perspective\:1000px\] {
  perspective: 1000px;
}

.\[transform-style\:preserve-3d\] {
  transform-style: preserve-3d;
}

.\[transform\:rotateY\(180deg\)\] {
  transform: rotateY(180deg);
}

.first-letter\:uppercase:first-letter {
  text-transform: uppercase;
}

.placeholder\:text-sm::placeholder {
  font-size: .875rem;
  line-height: 1.25rem;
}

.placeholder\:text-xs::placeholder {
  font-size: .75rem;
  line-height: 1rem;
}

.placeholder\:uppercase::placeholder {
  text-transform: uppercase;
}

.placeholder\:text-white\/20::placeholder {
  color: #fff3;
}

.backdrop\:bg-black\/60::backdrop {
  background-color: #0009;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:inset-0:before {
  content: var(--tw-content);
  inset: 0;
}

.before\:border-\[0\.5px\]:before {
  content: var(--tw-content);
  border-width: .5px;
}

.before\:content-\[\"EARNINGS\:_\"\]:before {
  --tw-content: "EARNINGS: ";
  content: var(--tw-content);
}

.before\:content-\[\"K\/D\:_\"\]:before {
  --tw-content: "K/D: ";
  content: var(--tw-content);
}

.before\:content-\[\"KILLS\:_\"\]:before {
  --tw-content: "KILLS: ";
  content: var(--tw-content);
}

.before\:content-\[\"PLAYER\:_\"\]:before {
  --tw-content: "PLAYER: ";
  content: var(--tw-content);
}

.before\:content-\[\"POTENTIAL_REWARD\:_\"\]:before {
  --tw-content: "POTENTIAL REWARD: ";
  content: var(--tw-content);
}

.before\:content-\[\"SESSIONS\:_\"\]:before {
  --tw-content: "SESSIONS: ";
  content: var(--tw-content);
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:left-0:after {
  content: var(--tw-content);
  left: 0;
}

.after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.after\:h-4:after {
  content: var(--tw-content);
  height: 1rem;
}

.after\:w-4:after {
  content: var(--tw-content);
  width: 1rem;
}

.after\:-translate-y-1\/4:after {
  content: var(--tw-content);
  --tw-translate-y: -25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:animate-\[textUnknown_3s_linear_infinite\]:after {
  content: var(--tw-content);
  animation: 3s linear infinite textUnknown;
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:bg-white:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:content-\[\'\@\#\$\%\'\]:after {
  --tw-content: "@#$%";
  content: var(--tw-content);
}

.odd\:bg-gray-400\/10:nth-child(odd) {
  background-color: #9ca3af1a;
}

.open\:flex[open] {
  display: flex;
}

.checked\:bg-\[\#C91FFF\]:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(201 31 255 / var(--tw-bg-opacity));
}

.checked\:opacity-100:checked {
  opacity: 1;
}

.hover\:z-10:hover {
  z-index: 10;
}

.hover\:scale-100:hover {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-\[1\.02\]:hover {
  --tw-scale-x: 1.02;
  --tw-scale-y: 1.02;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-\[102\%\]:hover {
  --tw-scale-x: 102%;
  --tw-scale-y: 102%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-\[\#E87F00\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(232 127 0 / var(--tw-border-opacity));
}

.hover\:border-\[\#FFD600\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 214 0 / var(--tw-border-opacity));
}

.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.hover\:border-white\/20:hover {
  border-color: #fff3;
}

.hover\:border-white\/30:hover {
  border-color: #ffffff4d;
}

.hover\:border-white\/50:hover {
  border-color: #ffffff80;
}

.hover\:bg-\[\#4D4D4D\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(77 77 77 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#5A3100\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 49 0 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#A18700\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(161 135 0 / var(--tw-bg-opacity));
}

.hover\:bg-black\/20:hover {
  background-color: #0003;
}

.hover\:bg-white\/10:hover {
  background-color: #ffffff1a;
}

.hover\:bg-white\/5:hover {
  background-color: #ffffff0d;
}

.hover\:bg-\[linear-gradient\(141deg\,rgba\(201\,31\,255\,0\.50\)_-11\.37\%\,rgba\(3\,206\,255\,0\.50\)_133\.98\%\)\]:hover {
  background-image: linear-gradient(141deg, #c91fff80 -11.37%, #03ceff80 133.98%);
}

.hover\:text-\[\#C91FFF\]:hover {
  --tw-text-opacity: 1;
  color: rgb(201 31 255 / var(--tw-text-opacity));
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.hover\:text-inherit:hover {
  color: inherit;
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.hover\:text-sky-500:hover {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

.hover\:text-violet-500:hover {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:outline:hover {
  outline-style: solid;
}

.hover\:outline-white:hover {
  outline-color: #fff;
}

.hover\:brightness-125:hover {
  --tw-brightness: brightness(1.25);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.focus\:border-white\/50:focus {
  border-color: #ffffff80;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.enabled\:hover\:border-white\/50:hover:enabled {
  border-color: #ffffff80;
}

.enabled\:hover\:bg-gray-400\/10:hover:enabled {
  background-color: #9ca3af1a;
}

.hover\:enabled\:outline:enabled:hover {
  outline-style: solid;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:border-black:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.disabled\:opacity-0:disabled {
  opacity: 0;
}

.disabled\:opacity-10:disabled {
  opacity: .1;
}

.disabled\:opacity-20:disabled {
  opacity: .2;
}

.disabled\:opacity-30:disabled {
  opacity: .3;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.disabled\:outline-none:disabled {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:transition-none:disabled {
  transition-property: none;
}

.disabled\:hover\:scale-100:hover:disabled {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.disabled\:hover\:text-inherit:hover:disabled {
  color: inherit;
}

.disabled\:hover\:outline-none:hover:disabled {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group:hover .group-hover\:bg-white\/10 {
  background-color: #ffffff1a;
}

.group:hover .group-hover\:stroke-white {
  stroke: #fff;
}

.group:hover .group-hover\:text-white\/100 {
  color: #fff;
}

.group:hover .group-hover\:underline {
  text-decoration-line: underline;
}

.group:hover .group-hover\:outline {
  outline-style: solid;
}

.group:disabled .group-disabled\:opacity-20 {
  opacity: .2;
}

.peer:checked ~ .peer-checked\:bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.peer:checked ~ .peer-checked\:text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.peer:checked ~ .peer-checked\:text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.peer:checked ~ .peer-checked\:opacity-100 {
  opacity: 1;
}

.peer:checked ~ .peer-checked\:after\:translate-x-full:after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:after\:bg-\[rgb\(201\,31\,255\)\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(201 31 255 / var(--tw-bg-opacity));
}

@media (prefers-color-scheme: dark) {
  .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
  }
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:right-32 {
    right: 8rem;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-start-3 {
    grid-column-start: 3;
  }

  .md\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .md\:row-start-auto {
    grid-row-start: auto;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:table-cell {
    display: table-cell;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-28 {
    height: 7rem;
  }

  .md\:h-5 {
    height: 1.25rem;
  }

  .md\:h-52 {
    height: 13rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:max-h-\[25vw\] {
    max-height: 25vw;
  }

  .md\:max-h-\[min\(25vw\,480px\)\] {
    max-height: min(25vw, 480px);
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:max-w-0 {
    max-width: 0;
  }

  .md\:max-w-5xl {
    max-width: 64rem;
  }

  .md\:max-w-full {
    max-width: 100%;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:flex-auto {
    flex: auto;
  }

  .md\:basis-\[30\%\] {
    flex-basis: 30%;
  }

  .md\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:-rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-\[15\%_auto_15\%\] {
    grid-template-columns: 15% auto 15%;
  }

  .md\:grid-cols-\[1fr\,max-content\,1fr\] {
    grid-template-columns: 1fr max-content 1fr;
  }

  .md\:grid-cols-\[1fr\,max-content\,3fr\,1fr\] {
    grid-template-columns: 1fr max-content 3fr 1fr;
  }

  .md\:grid-cols-\[2fr\,1fr\,2fr\] {
    grid-template-columns: 2fr 1fr 2fr;
  }

  .md\:grid-cols-\[4rem\,10rem\] {
    grid-template-columns: 4rem 10rem;
  }

  .md\:grid-cols-\[max-content\,auto\] {
    grid-template-columns: max-content auto;
  }

  .md\:grid-cols-\[repeat\(3\,minmax\(max-content\,1fr\)\)\] {
    grid-template-columns: repeat(3, minmax(max-content, 1fr));
  }

  .md\:grid-cols-\[repeat\(4\,max-content\)\] {
    grid-template-columns: repeat(4, max-content);
  }

  .md\:grid-cols-\[repeat\(4\,minmax\(max-content\,1fr\)\)\] {
    grid-template-columns: repeat(4, minmax(max-content, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-16 {
    gap: 4rem;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .md\:gap-x-8 {
    column-gap: 2rem;
  }

  .md\:gap-y-1 {
    row-gap: .25rem;
  }

  .md\:gap-y-10 {
    row-gap: 2.5rem;
  }

  .md\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .md\:justify-self-end {
    justify-self: end;
  }

  .md\:bg-black\/20 {
    background-color: #0003;
  }

  .md\:bg-cover {
    background-size: cover;
  }

  .md\:object-center {
    object-position: center;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:p-12 {
    padding: 3rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:pl-16 {
    padding-left: 4rem;
  }

  .md\:pl-4 {
    padding-left: 1rem;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pr-2 {
    padding-right: .5rem;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pt-10 {
    padding-top: 2.5rem;
  }

  .md\:pt-4 {
    padding-top: 1rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .md\:text-\[0\.6rem\] {
    font-size: .6rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .md\:opacity-0 {
    opacity: 0;
  }

  .md\:blur-\[16px\] {
    --tw-blur: blur(16px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:transition-none {
    transition-property: none;
  }
}

@media (width >= 1024px) {
  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:table-cell {
    display: table-cell;
  }

  .lg\:table-header-group {
    display: table-header-group;
  }

  .lg\:table-row {
    display: table-row;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:w-12 {
    width: 3rem;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:max-w-xl {
    max-width: 36rem;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-cols-\[2fr\,3fr\,1fr\] {
    grid-template-columns: 2fr 3fr 1fr;
  }

  .lg\:grid-cols-\[max-content\,auto\] {
    grid-template-columns: max-content auto;
  }

  .lg\:grid-cols-\[repeat\(3\,max-content\)\] {
    grid-template-columns: repeat(3, max-content);
  }

  .lg\:grid-cols-\[repeat\(5\,max-content\)\,1fr\] {
    grid-template-columns: repeat(5, max-content) 1fr;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:gap-x-16 {
    column-gap: 4rem;
  }

  .lg\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .lg\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .lg\:gap-y-7 {
    row-gap: 1.75rem;
  }

  .lg\:gap-y-8 {
    row-gap: 2rem;
  }

  .lg\:border-0 {
    border-width: 0;
  }

  .lg\:border-l-8 {
    border-left-width: 8px;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:pr-20 {
    padding-right: 5rem;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .lg\:leading-loose {
    line-height: 2;
  }

  .lg\:blur-xl {
    --tw-blur: blur(24px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:before\:content-none:before {
    --tw-content: none;
    content: var(--tw-content);
  }
}

@media (width >= 1280px) {
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
}

@media (width >= 1536px) {
  .\32 xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .\32 xl\:p-24 {
    padding: 6rem;
  }

  .\32 xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .\32 xl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
}

.\[\&\>\:first-child\]\:pr-2 > :first-child, .\[\&\>button\>\:first-child\]\:pr-2 > button > :first-child {
  padding-right: .5rem;
}

.\[\&\>button\]\:p-2 > button {
  padding: .5rem;
}

.\[\&\>button\]\:underline-offset-4 > button {
  text-underline-offset: 4px;
}

.\[\&\>button\]\:hover\:underline:hover > button {
  text-decoration-line: underline;
}

.\[\&\>div\]\:flex > div {
  display: flex;
}

.\[\&\>div\]\:flex-col > div {
  flex-direction: column;
}

.\[\&\>div\]\:border > div {
  border-width: 1px;
}

.\[\&\>div\]\:border-gray-700\/10 > div {
  border-color: #3741511a;
}

.\[\&\>div\]\:border-white\/10 > div {
  border-color: #ffffff1a;
}

.\[\&\>div\]\:bg-blue-500\/10 > div {
  background-color: #3b82f61a;
}

.\[\&\>div\]\:p-4 > div {
  padding: 1rem;
}

.\[\&\>div\]\:hover\:bg-white\/10:hover > div {
  background-color: #ffffff1a;
}

.\[\&_\*\]\:p-2 * {
  padding: .5rem;
}

.\[\&_\*\]\:px-6 * {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.\[\&_header\]\:text-left header {
  text-align: left;
}

.\[\&_header\]\:text-white\/50 header {
  color: #ffffff80;
}

.\[\&_input\:\:-webkit-calendar-picker-indicator\]\:invert input::-webkit-calendar-picker-indicator {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\[\&_input\]\:rounded input {
  border-radius: .25rem;
}

.\[\&_input\]\:border input {
  border-width: 1px;
}

.\[\&_input\]\:border-white\/20 input {
  border-color: #fff3;
}

.\[\&_input\]\:bg-black input {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.\[\&_input\]\:p-2 input {
  padding: .5rem;
}

.\[\&_input\]\:outline-none input {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.\[\&_input\]\:placeholder\:text-white\/30 input::placeholder {
  color: #ffffff4d;
}

.\[\&_span\]\:uppercase span {
  text-transform: uppercase;
}

.wallet-page {
  flex-direction: column;
  align-items: center;
  max-width: 1920px;
  min-height: 100vh;
  padding-bottom: 2rem;
  font-weight: 900;
  display: flex;
}

.wallet-page .break-before:before {
  content: "\a ";
  white-space: pre;
}

.wallet-page--newbie {
  --primary-color: #9c035b;
  --secondary-color: #e42575;
  --primary-color-hover: #d00078;
  --secondary-color-hover: #ff3b8d;
}

.wallet-page--veteran {
  --primary-color: #007e47;
  --secondary-color: #00c06c;
  --primary-color-hover: #00a55d;
  --secondary-color-hover: #44ffae;
}

.wallet-page__logo {
  background: url("header__bg.8db049e8.png") center / contain no-repeat, #ffffff1a;
  width: 100%;
  padding-top: 19.21%;
}

.wallet-page > header {
  text-align: center;
  white-space: nowrap;
  padding: 2rem;
}

.wallet-page > header > h1 {
  font-size: clamp(1.75rem, 1.5375rem + 1.0625vw, 2.8125rem);
  line-height: 1.2;
}

.wallet-page > header > h2 {
  padding-top: 1rem;
  font-size: clamp(1.2rem, 1.09rem + .55vw, 1.75rem);
  line-height: 1.2;
}

.wallet-page > header > h3 {
  padding-top: 1rem;
  font-size: clamp(.8rem, .6475rem + .7625vw, 1.5625rem);
}

.wallet-page__grid {
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 100%;
  min-width: 300px;
  max-width: 1440px;
  padding: 0 1rem;
  font-size: clamp(.8rem, .5714rem + 1vw, 1.6rem);
  display: grid;
}

.wallet-page__choice {
  background-color: var(--primary-color);
  border: 7px solid var(--secondary-color);
  white-space: nowrap;
  cursor: pointer;
  grid-template-columns: auto 5em;
  align-items: center;
  gap: .5rem;
  padding: .5em;
  display: grid;
  overflow: hidden;
}

.wallet-page__choice > div > h2 {
  font-size: 1em;
}

.wallet-page__choice > div > h3 {
  font-size: .8em;
}

.wallet-page__choice > figure {
  box-sizing: border-box;
  background-color: #0000004d;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 5em;
  padding: 10%;
  display: flex;
  overflow: hidden;
}

.wallet-page__choice > figure > img {
  object-fit: contain;
  width: 90%;
  height: 90%;
  margin: 0 auto;
}

.wallet-page__block {
  display: none;
}

#wallet-page__toggle:checked ~ .wallet-page__block {
  display: unset;
}

.wallet-page__block > header {
  padding-bottom: 1em;
  font-size: .8em;
}

.wallet-page__block > div {
  background-color: var(--primary-color);
  border: 7px solid var(--secondary-color);
  padding: .5rem;
}

.wallet-page__info-container {
  flex-direction: column;
  gap: .5em;
  display: flex;
}

.wallet-page__info {
  background-color: #00000059;
  padding: 1em;
}

.wallet-page__info > header {
  font-size: .8em;
}

.wallet-page__info > p {
  color: #ffffffa6;
  margin-top: 1em;
  font-size: .7em;
}

.wallet-page__connect-wallet {
  white-space: nowrap;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-size: .8em;
  display: flex;
}

.wallet-page__choose-wallet {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: .5rem 0;
  display: flex;
}

.wallet-page__wallet-icon {
  cursor: pointer;
  border-radius: .5rem;
  width: 3rem;
  height: 3rem;
  padding: .5rem;
}

.wallet-page__wallet-icon[data-selected="true"] {
  background-color: #00000059;
}

.wallet-page__connect-button {
  color: #fff;
  cursor: pointer;
  background: linear-gradient(273deg, #101010 -25.82%, #555 122.59%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5rem 1rem;
  font-size: .8em;
  display: flex;
}

.wallet-page__connect-button[disabled] {
  opacity: .5;
  cursor: unset;
}

.wallet-page__connect-button[data-connected="true"] {
  opacity: 1;
  background: #00000059;
}

.wallet-page__is-ledger {
  cursor: pointer;
  font-size: .8em;
}

.wallet-page__is-ledger:has(input:disabled) {
  opacity: .5;
}

#wallet-page__is-ledger__toggle {
  appearance: none;
  font-size: inherit;
  box-sizing: content-box;
  vertical-align: text-bottom;
  color: inherit;
  background: #1c1c1c;
  width: 3em;
  height: 1em;
  margin: auto;
  position: relative;
}

#wallet-page__is-ledger__toggle:before {
  content: "";
  box-sizing: border-box;
  background: #373737;
  width: 1.4em;
  height: 1.4em;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

#wallet-page__is-ledger__toggle:checked:before {
  left: 1.6em;
}

.wallet-page__lines.wallet-page--newbie {
  border-right: 7px solid;
  border-color: var(--secondary-color);
  grid-area: 2 / 1 / 3 / 3;
  width: calc(57% - .5rem - 3em);
  height: 2.8em;
  margin-top: -1rem;
  display: none;
}

.wallet-page__lines.wallet-page--veteran {
  border-bottom: 7px solid;
  border-right: 7px solid;
  border-color: var(--secondary-color);
  grid-area: 2 / 1 / 5 / 3;
  width: calc(100% - 3em);
  height: calc(100% - 2em);
  margin-top: -1rem;
  display: none;
}

.wallet-page__skip-button {
  background: linear-gradient(273deg, #101010 -25.82%, #555 122.59%);
  margin-top: 2rem;
  padding: 1rem;
  font-size: clamp(.8rem, .5714rem + 1vw, 1.6rem);
}

.wallet-page__skip-button:hover {
  background: linear-gradient(273deg, #c91fff -25.82%, #03ceff 122.59%);
}

@media screen and (width >= 768px) {
  .wallet-page .break-before:before {
    content: none;
  }

  .wallet-page__grid {
    grid-template-columns: 57fr 43fr;
  }

  .wallet-page__grid:has(.wallet-page__choice.wallet-page--newbie:hover) .wallet-page--newbie {
    --primary-color: var(--primary-color-hover);
    --secondary-color: var(--secondary-color-hover);
  }

  .wallet-page__grid:has(.wallet-page__choice.wallet-page--veteran:hover) .wallet-page--veteran {
    --primary-color: var(--primary-color-hover);
    --secondary-color: var(--secondary-color-hover);
  }

  .wallet-page__choice.wallet-page--newbie {
    grid-area: 1 / 1;
  }

  .wallet-page__choice.wallet-page--veteran {
    grid-area: 1 / 2;
  }

  .wallet-page__block.wallet-page--newbie {
    grid-area: 2 / 1 / auto / 3;
    width: 90%;
  }

  .wallet-page__block.wallet-page--veteran {
    grid-area: 3 / 1 / auto / 3;
    width: min-content;
  }

  .wallet-page__connect-wallet {
    flex-direction: row;
  }

  #wallet-page__toggle:checked ~ .wallet-page__lines.wallet-page--newbie, #wallet-page__toggle:checked ~ .wallet-page__lines.wallet-page--veteran {
    display: unset;
  }
}

/*# sourceMappingURL=index.c7c38bea.css.map */
