@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@400;700&family=Jura:wght@300;400;500;600;700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --color-gradient: linear-gradient(75deg, #03ceff, #c91fff, #03ceff, #c91fff);
        --color-gradient-2: linear-gradient(90deg, #c7b303, #040404, #c7b303);
        /* --color-gradient: linear-gradient(90deg, rgba(3,206,255,1) 0%, rgba(96,183,255,1) 25%, rgba(221,73,255,1) 75%, rgba(201,31,255,1) 100%); */
    }

    @font-face {
        font-family: Promo;
        font-weight: 400;
        src: url('../fonts/promo.woff2') format('woff');
    }

    html {
        font-family: Orbitron, sans-serif;
        -webkit-tap-highlight-color: transparent;
        /* height: 100vh; */
        background-color: #000;
    }

    /* html, */
    body {
        /* overflow: hidden; */
        height: 100%;
        width: 100%;
        background: #000;
        color: #ffffff;
    }

    #app-root {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }

    body:has(dialog:open) {
        overflow: hidden;
    }

    .page {
        max-width: 1920px;
        margin: 0 auto;
    }

    #landing h2 {
        @apply text-3xl font-bold lg:text-5xl;
    }

    #landing h3 {
        @apply text-xl font-bold lg:text-2xl;
    }
}

@layer components {
    .toggle-checkbox {
        @apply flex cursor-pointer flex-row items-center gap-x-2;

        > input {
            display: none;

            &:checked + span {
                background-color: #42d77d;
            }

            &:checked + span::after {
                left: 1em;
            }
        }

        > span {
            @apply relative inline-block cursor-pointer transition-all duration-300;
            width: 2em;
            height: 1em;
            background-color: #d7d8da;
            border-radius: 15px;

            &:after {
                content: '';
                position: absolute;
                left: 0.03em;
                top: 0.03em;
                width: 0.93em;
                height: 0.93em;
                border-radius: 15px;
                background-color: #ffffff;
                transition: left 0.3s ease-out;
            }
        }
    }

    .button-gradient {
        background-image: var(--color-gradient);
        position: relative;
        z-index: 1;
        font-weight: bold;
        background-size: 300%;
        padding: 0.6em 3em;
    }

    .button-gradient::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: var(--color-gradient);
        filter: blur(7px);
        z-index: -1;
        background-size: 300%;
    }

    .waiting-gradient {
        background-image: var(--color-gradient-2);
        position: relative;
        z-index: 1;
        font-weight: bold;
        background-size: 300%;
        padding: 0.6em 3em;
    }

    .waiting-gradient::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: var(--color-gradient-2);
        filter: blur(7px);
        z-index: -1;
        background-size: 300%;
    }

    .button-gradient-animate {
        animation: backgroud-gradient-animation 8s infinite;
    }

    .button-gradient-animate::after {
        animation: backgroud-gradient-animation 8s infinite;
    }

    @keyframes backgroud-gradient-animation {
        0% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    .button-default {
        @apply rounded-lg bg-[linear-gradient(100deg,#C91FFF_-15%,#03CEFF_170%)] py-2 px-8 font-semibold transition-all hover:scale-[105%] hover:outline;
    }

    .button-default-mirrored {
        @apply rounded-lg bg-[linear-gradient(270deg,#C91FFF_-15%,#03CEFF_170%)] py-2 px-8 font-semibold transition-all hover:scale-[105%] hover:outline;
    }

    .input-team-tools {
        @apply rounded border border-white border-opacity-20 bg-inherit p-2 text-inherit outline-none placeholder:opacity-50;
    }

    .input-team-tools::-webkit-calendar-picker-indicator {
        filter: invert(1);
        cursor: pointer;
    }

    input.login-form {
        @apply w-full p-4 bg-black/50 backdrop-blur-xl border text-center;
    }
    .login-form.error {
        @apply text-red-500 bg-black/50 backdrop-blur-xl p-1 text-sm font-bold;
    }
    .login-form.success {
        @apply text-green-500 bg-black/50 backdrop-blur-xl p-1 text-sm font-bold;
    }
    .login-form.error:empty {
        display: none;
    }
}

@layer utilities {
    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .flat-scrollbar::-webkit-scrollbar {
        background-color: rgba(255, 255, 255, 0.15);
        width: 0.75em;
    }

    .flat-scrollbar::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.59);
    }

    .bg-silver {
        background: linear-gradient(180deg, #ffffff 23.37%, rgba(107, 107, 107, 0.72) 175%);
    }

    .bg-neon {
        background: linear-gradient(91.62deg, #03ceff -21.66%, #c91fff 104.15%);
    }

    .clip-text {
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
    }

    .text-neon-glow {
        position: relative;
    }

    .text-neon-glow::after {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(91.62deg, #03ceff -21.66%, #c91fff 104.15%);
        background-clip: text;
        -webkit-background-clip: text;
        filter: blur(4px);
    }

    .gradient-text-landing {
        background: linear-gradient(180deg, #ffffff 0%, #999999 140%);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
    }

    .gradient-text {
        background: linear-gradient(269.59deg, #03ceff -11.05%, #c91fff 52.25%, #00ff90 112.98%);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
    }

    .gradient-text-2 {
        background: linear-gradient(269.59deg, #03ceff -11.05%, #c91fff 52.25%, #00ff90 112.98%);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
    }

    .gradient-vertical-opacity {
        background: linear-gradient(180deg, rgba(21, 18, 24, 0) 0%, #000 100%);
    }

    .scrollbar-tiny::-webkit-scrollbar {
        width: 12px;
        background: transparent;
    }

    .scrollbar-tiny::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 14px 14px #bbbbbe;
        border: solid 4px transparent;
        border-radius: 14px;
    }

    .scrollbar-tiny::-webkit-scrollbar-track {
        box-shadow: inset 0 0 14px 14px transparent;
        border: solid 4px transparent;
    }

    .no-flip {
        transform: rotateY(0);
        z-index: 0;
    }

    .-rotate-flip {
        transform: rotateY(-180deg) translateX(1);
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        animation: z-layer 0.8s;
    }

    .rotate-flip {
        transform: rotateY(180deg) translateX(-1);
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        animation: z-layer 0.8s;
    }

    .perspective {
        perspective: 900px;
    }

    @keyframes z-layer {
        0% {
            z-index: 1;
        }

        100% {
            z-index: 0;
        }
    }

    .animate-fade-in-out {
        animation: fadeInOut 1s linear forwards;
    }

    @keyframes fadeInOut {
        0% {
            transform: translate(-50%, 0);
            opacity: 0;
        }

        50% {
            transform: translate(-50%, -50%);
            opacity: 0.8;
        }
        100% {
            transform: translate(-50%, -100%);
            opacity: 0;
        }
    }

    @keyframes textUnknown {
        0% {
            content: '@#$%';
        }
        5% {
            content: '#$%@';
        }
        10% {
            content: '$%@#';
        }
        15% {
            content: '%@#$';
        }
        20% {
            content: '@#$%';
        }
    }
}
